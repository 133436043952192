import React from "react";
import { Link } from "gatsby";

const BlogCategoriesNav = ({ categories }) => {
  return (
    <div className="categories-nav">
      {categories.length &&
        categories.map((category) => {
          const catLink = category.toLowerCase().replaceAll(" ", "-");
          return (
            <Link
              to={`/${catLink === "promotions" ? "campaigns" : catLink}/`}
              key={catLink}
              activeStyle={{
                color: "#6AB344",
                borderBottom: "1px solid #6AB344",
              }}
            >
              {category === "Promotions" ? "Campaigns" : category}
            </Link>
          );
        })}
    </div>
  );
};

export default BlogCategoriesNav;
