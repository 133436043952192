import React, { useState } from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { capitalizeText } from "../utils/helpers";

const BlogCardsListing = ({ cardsList }) => {
  const [cardsToShow, setCardsToShow] = useState(13);

  function addCards() {
    setCardsToShow(cardsToShow + 12);
  }

  return (
    <div className="articles-grid row">
      {cardsList.length &&
        cardsList.slice(0, cardsToShow).map((cardItem, index) => {
          const image = getImage(cardItem?.node?.featuredImage);

          let category;

          if (cardItem?.node?.categories !== undefined) {
            category = cardItem?.node?.categories[0]?.toLowerCase();
          } else category = "";

          let categoryLink;
          if (category === "promotions") {
            categoryLink = "campaigns";
          } else {
            categoryLink = category;
          }
          const catName = capitalizeText(categoryLink);
          return index === 0 ? (
            <></>
          ) : (
            <div
              className="articles-grid__item col-12 col-xl-3 col-md-4"
              key={cardItem?.node?.title}
            >
              <article className="card article-card card--article post-11835 post type-post status-publish format-standard has-post-thumbnail hentry category-rahapelit">
                <GatsbyImage image={image} alt={cardItem?.node?.title} />
                <div className="card-body">
                  <header>
                    <h2 className="entry-title card-title">
                      <Link
                        to={`/${categoryLink.replace(" ", "-")}/${
                          cardItem?.node?.slug
                        }/`}
                        class="stretched-link"
                      >
                        {cardItem?.node?.title}
                      </Link>
                    </h2>
                  </header>
                  <footer>
                    <div className="article-card__meta entry-meta">
                      <time
                        className="updated"
                        dateTime="2022-02-11T11:29:16+00:00"
                      >
                        {cardItem?.node?.createdData}
                      </time>
                      <div className="categories">{catName}</div>
                    </div>
                  </footer>
                </div>
              </article>
            </div>
          );
        })}
      {cardsToShow < cardsList?.length && (
        <div className="load-more-button">
          <button onClick={addCards} className="btn btn-white">
            More options
          </button>
        </div>
      )}
    </div>
  );
};

export default BlogCardsListing;
