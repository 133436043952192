import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";

// Components
import ContentComponent from "../components/ContentComponent";
import FaqComponent from "../components/FaqComponent";
import HowToComponent from "../components/HowToComponent";
import AccordionComponent from "../components/AccordionComponent";
import CasinosList from "../components/CasinosList";
import CtaComponent from "../components/CallToActionComponents";
import FeaturedPosts from "../components/FeaturedPosts";
import GameOfTheMonth from "../components/GameOfTheMonth";
import HeroSection from "../components/HeroSection";
import FeaturedPost from "../components/FeaturedPost";
import BlogCardsListing from "../components/BlogCardsListing";
import BlogCategoriesNav from "../components/BlogCategoriesNav";
import Seo from "../components/Seo";

const BogListingPage = ({ data }) => {
  const pageData = data?.pageData;
  const allPosts = data?.allPosts?.edges;
  const categoriesPosts = data?.categoriesPosts?.edges;
  const components = pageData?.components;

  const { featuredPost } = data;
  const categorySet = new Set();

  categoriesPosts.forEach((post) => {
    post.node.categories[0] && categorySet.add(post.node.categories[0]);
  });
  const categoryList = Array.from(categorySet);

  return (
    <Layout>
      <div className="content container">
        {categoryList && <BlogCategoriesNav categories={categoryList} />}
        <FeaturedPost
          title={featuredPost.edges[0]?.node?.title}
          createdDate={featuredPost?.edges[0].node?.createdData}
          featuredImage={featuredPost?.edges[0].node?.featuredImage}
          categories={featuredPost?.edges[0].node?.categories}
          slug={featuredPost?.edges[0].node?.slug}
          excerpt={
            featuredPost?.edges[0].node?.description?.childMarkdownRemark?.html
          }
          cardDescription={
            featuredPost?.edges[0].node?.cardDescription.cardDescription
          }
        />
        <BlogCardsListing cardsList={allPosts} />
        {components?.map((component) => {
          switch (component.__typename) {
            case "ContentfulSeoComponent":
              return (
                <Seo
                  title={component?.seoTitle}
                  description={component?.seoDescription}
                  url={`https://list.casino/${component?.seoSlug}/`}
                  canonical={`https://list.casino/${component?.seoSlug}/`}
                />
              );
            case "ContentfulHeroComponent":
              return (
                <HeroSection
                  title={component?.title}
                  casinosCadsData={component?.topCasinos}
                  backgroundColor={component?.backgroundColor}
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                />
              );
            case "ContentfulAccordionComponent":
              return (
                <AccordionComponent
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                  accordionText={
                    component?.accordionContent?.childMarkdownRemark?.html
                  }
                />
              );
            case "ContentfulCallToAction":
              return (
                <CtaComponent
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                  title={component?.title}
                  ctaCards={component?.callToActionCards}
                />
              );
            case "ContentfulGameOfTheMonth":
              return (
                <GameOfTheMonth
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                  gameName={component?.gameName}
                  gameImage={component?.gameImage}
                  gameDescription={
                    component?.gameDescription?.childMarkdownRemark?.html
                  }
                  playGameLink={component?.playGameLink}
                  link={component?.link}
                />
              );
            case "ContentfulFeaturedPosts":
              return (
                <FeaturedPosts
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                  title={component?.title}
                  postCards={component?.relatedPosts}
                />
              );
            case "ContentfulRelatedCasinosList":
              return (
                <CasinosList
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                  title={component?.title}
                  casinosCadsData={component?.casinosList}
                />
              );
            case "ContentfulHowTo":
              return (
                <HowToComponent
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                  title={component?.title}
                  description={component?.description}
                  duration={component?.duration}
                  estimatedCost={component?.estimatedCost}
                  howToCards={component?.howToCards}
                />
              );
            case "ContentfulContentComponent":
              return (
                <ContentComponent
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                  data={component?.contentText?.childMarkdownRemark?.html}
                />
              );
            case "ContentfulFaqComponent":
              return (
                <FaqComponent
                  marginTop={component.marginTop}
                  marginBottom={component.marginBottom}
                  faqItems={component?.faqItems}
                  title={component?.title}
                />
              );
            default:
              return <></>;
          }
        })}
      </div>
    </Layout>
  );
};

export default BogListingPage;

export const query = graphql`
  query blogListingPage($id: String!, $category: String!) {
    categoriesPosts: allContentfulBlogPost {
      edges {
        node {
          id
          slug
          categories
        }
      }
    }
    featuredPost: allContentfulBlogPost(
      filter: { categories: { eq: $category } }
      sort: { fields: createdData, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          id
          categories
          createdData(formatString: "DD.MM.YYYY")
          title
          slug
          cardDescription {
            cardDescription
          }
          featuredImage {
            gatsbyImage(
              width: 900
              quality: 100
              placeholder: NONE
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
    allPosts: allContentfulBlogPost(
      filter: { categories: { eq: $category } }
      sort: { fields: createdData, order: DESC }
      skip: 1
    ) {
      edges {
        node {
          id
          categories
          createdData(formatString: "DD.MM.YYYY")
          title
          slug
          description {
            childMarkdownRemark {
              html
            }
          }
          featuredImage {
            gatsbyImage(
              width: 300
              quality: 100
              placeholder: NONE
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
    pageData: contentfulWebsitePage(id: { eq: $id }) {
      id
      pageName
      components {
        ... on ContentfulAccordionComponent {
          id
          __typename
          marginTop
          marginBottom
          accordionContent {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulSeoComponent {
          id
          __typename
          seoSlug
          seoTitle
          seoDescription
        }
        ... on ContentfulCallToAction {
          id
          marginTop
          marginBottom
          __typename
          title
          callToActionCards {
            title
            slug
            icon {
              file {
                url
              }
            }
          }
        }
        ... on ContentfulContentComponent {
          id
          __typename
          marginTop
          marginBottom
          contentText {
            contentText
            childMarkdownRemark {
              html
            }
          }
        }
        ... on ContentfulFaqComponent {
          id
          __typename
          title
          marginTop
          marginBottom
          faqItems {
            faqAnswer {
              faqAnswer
              childMarkdownRemark {
                html
              }
            }
            faqQuestion
          }
        }
        ... on ContentfulFeaturedPosts {
          id
          __typename
          title
          marginTop
          marginBottom
          relatedPosts {
            title
            createdData
            categories
            slug
            featuredImage {
              gatsbyImage(
                width: 400
                quality: 100
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        ... on ContentfulGameOfTheMonth {
          id
          marginTop
          marginBottom
          __typename
          gameName
          link
          gameImage {
            gatsbyImage(
              width: 400
              quality: 100
              placeholder: NONE
              formats: [AUTO, WEBP]
            )
          }
          gameDescription {
            childMarkdownRemark {
              html
            }
          }
          playGameLink {
            linkText
            linkType
            linkRoute
          }
        }
        ... on ContentfulHeroComponent {
          id
          __typename
          title
          marginTop
          marginBottom
          backgroundColor
          topCasinos {
            casinoName
            shortDescription
            logoBackgroundColor
            slug
            categories
            referralUrl
            rating
            casinoAward
            awardTextColor
            logo {
              gatsbyImage(
                width: 120
                quality: 100
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        ... on ContentfulHowTo {
          id
          __typename
          title
          marginTop
          marginBottom
          duration
          estimatedCost
          description {
            id
            childMarkdownRemark {
              html
            }
          }
          howToCards {
            title
            description
            labelText
            labelIndex
            image {
              gatsbyImage(
                width: 270
                quality: 100
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
              file {
                url
              }
            }
          }
        }
        ... on ContentfulRelatedCasinosList {
          id
          __typename
          title
          marginTop
          marginBottom
          casinosList {
            casinoName
            shortDescription
            logoBackgroundColor
            slug
            categories
            referralUrl
            rating
            logo {
              gatsbyImage(
                width: 120
                quality: 100
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`;
