import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { capitalizeText } from "../utils/helpers";

const BlogHero = ({
  title,
  slug,
  createdDate,
  featuredImage,
  categories,
  excerpt,
  cardDescription,
}) => {
  const image = getImage(featuredImage);
  const truncateText = (text, limit) => {
    return text && (text.length <= limit ? text : `${text.slice(0, limit)}`);
  };
  const truncatedText = truncateText(excerpt, 210);
  let category;

  if (categories !== null && categories !== undefined) {
    category = categories[0]?.toLowerCase();
  } else category = "";

  let categoryLink;
  if (category === "promotions") {
    categoryLink = "campaigns";
  } else {
    categoryLink = category;
  }
  const catName = capitalizeText(categoryLink);
  return (
    <div>
      <div className="row">
        <div className="col-xl-9">
          <Link
            to={`/${categoryLink.replace(" ", "-")}/${slug}/`}
            className="d-block image-wrapper feat-post-wrapper"
          >
            <GatsbyImage image={image} alt={title} objectFit="cover" />
          </Link>
        </div>
        <div className="col-xl-3">
          <header>
            <h1 className="entry-title card-title">
              <Link
                to={`/${categoryLink.replace(" ", "-")}/${slug}/`}
                className="stretched-link"
              >
                {title}
              </Link>
            </h1>
          </header>
          {cardDescription ? (
            <div className="entry-summary" style={{ marginBottom: "1rem" }}>
              {cardDescription}
            </div>
          ) : (
            <div className="entry-summary">
              <p dangerouslySetInnerHTML={{ __html: truncatedText }} />
            </div>
          )}

          <footer>
            <div className="article-card__meta entry-meta">
              <span className="updated">{createdDate}</span>
              <div className="categories">{catName}</div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default BlogHero;
